/* global isJestTest */
/**
 * CardNewsList
 */

import React from 'react';
import CardNews from 'Components/CardNews';
import { httpGet } from 'utils/Http';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { idToName, objToQuery } from 'utils/Helpers';
import { snakeCaseToCamelCase } from 'utils';
import FilterSelect from 'Components/FilterSelect';
import ButtonReadMore from 'Components/ButtonReadMore';
import AjaxLoader from 'Components/AjaxLoader';
import { dataLayerPush } from 'utils/Datalayer';
import Title from 'Components/Title';
import s from './CardNewsList.module.scss';

class CardNewsList extends React.PureComponent {
    pushState = false;
    constructor(props) {
        super(props);

        this.state = {
            showShowAllButton: false,
            fetching: true,
            selectedPlace: this.props.preselectedPlaces,
            selectedCategory: this.props.preselectedCategories,
            selectedBusinessConcepts: this.props.preselectedBusinessConcepts,
            selectedBusinessPartners: this.props.preselectedBusinessPartners,
            selectedYear: '',
            selectedMonth: '',
            page: 1,
            count: 0,
            entries: [],
        };
    }

    handlePlaceOnChange = (event) => {
        this.pushState = true;
        this.setState({ selectedPlace: [event.value], page: 1, entries: [] }, () =>
            this.fetchCards(false)
        );
    };

    handleCategoryOnChange = (event) => {
        this.pushState = true;
        this.setState({ selectedCategory: [event.value], page: 1, entries: [] }, () =>
            this.fetchCards(false)
        );
    };

    handleYearChange = (event) => {
        this.pushState = true;
        this.setState({
            selectedYear: parseInt(event.target.value) ? event.target.value : '',
            page: 1,
            entries: []
        }, () => this.fetchCards(false));
    };

    handleMonthChange = (event) => {
        this.pushState = true;
        this.setState({ selectedMonth: event.target.value, page: 1, entries: [] }, () =>
            this.fetchCards(false)
        );
    };

    handleListExtension = () => {
        const self = this;

        const { page } = this.state;

        this.pushState = false;

        this.setState({
            page: page + 1,
            fetching: true,
        }, () => {
            self.fetchCards(false);
        });
    }

    componentDidMount() {
        const self = this;
        if (!window?.isJestTest && typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);

            let state = {};
            const place = params.get('place');
            if(place) {
                state.selectedPlace = place.split(',').map((p) => parseInt(p));
            }
            const category = params.get('category');
            if(category) {
                state.selectedCategory = category.split(',').map((c) => parseInt(c));
            }
            const year = params.get('year');
            if(year) {
                state.selectedYear = year;
            }
            const month = params.get('month');
            if(month) {
                state.selectedMonth = month;
            }

            this.setState({...state}, () => {
                self.fetchCards(true);
            });

            this.pushState = false;

            window.onpopstate = function (event) {
                self.pushState = false;
                self.setState({
                    ...event.state,
                    fetching: true,
                }, () => {
                    self.fetchCards(false);
                });
            };
        }
    }

    getBaseUrl = () => {
        const url = window.location.host + window.location.pathname;
        return window.location.protocol + '//' + url;
    }

    fetchCards = (isInitialSearch) => {
        const {
            selectedPlace,
            selectedCategory,
            selectedYear,
            selectedMonth,
            selectedBusinessPartners,
            selectedBusinessConcepts,
            page,
            entries,
        } = this.state;

        const {
            places,
            categories,
            currentPage,
            showRelated,
            onShowComponent,
            onHideComponent,
            initialNumberOfCards,
            pageListId,
            showFeaturedFirst,
            priorityQuery,
            excludeKeywords,
        } = this.props;

        let url = '';
        if (showRelated) {
            url = `/wt/api/v2/related-news/?id=${currentPage}&page=1&take=3`;
        } else {
            const parsedPlace = selectedPlace ? selectedPlace.join(',') : '';
            const parsedCat = selectedCategory ? selectedCategory.join(',') : '';
            const queries = {
                exclude: currentPage || '',
                place: parsedPlace,
                category: parsedCat,
                year: selectedYear,
                month: selectedMonth,
                priorityQuery: priorityQuery ? priorityQuery.join(',') : '',
                excludeKeywords: excludeKeywords ? excludeKeywords.join(';') : '',
                businessPartners: selectedBusinessPartners ? selectedBusinessPartners.join(',') : '',
                businessConcepts: selectedBusinessConcepts ? selectedBusinessConcepts.join(',') : '',
                fallbackToParentPlace: isInitialSearch ? false : true,
                take: initialNumberOfCards || 3,
                page: page || 1,
                showFeaturedFirst: showFeaturedFirst || false,
            };

            url = `/wt/api/v2/news/${objToQuery(queries)}`;

            if (this.pushState) {
                const defaultPlace = places.length ? places[0] : '';
                const defaultCat = categories.length ? categories[0] : '';
                let pushQuery = {
                    year: selectedYear,
                    month: selectedMonth,
                };
                if(parsedPlace && defaultPlace && parsedPlace !== String(defaultPlace.id)) {
                    pushQuery.place = parsedPlace;
                }
                if(parsedCat && defaultCat && parsedCat !== String(defaultCat.id)) {
                    pushQuery.category = parsedCat;
                }
                const pushUrl = `${this.getBaseUrl()}${objToQuery(pushQuery)}`;
                history.pushState(this.state, '', pushUrl);
            }
        }

        this.setState({
            fetching: true,
        });

        httpGet(url).then((response) => {
            let formattedResponse = snakeCaseToCamelCase(response);
            this.setState({
                fetching: false,
                hideIfEmpty: true,
                count: formattedResponse.count,
                showShowAllButton: page === 4 && pageListId !== currentPage,
                entries: entries.concat(formattedResponse.results),
            });

            if (isInitialSearch && formattedResponse.results.length) {
                onShowComponent();
            } else if (isInitialSearch && !formattedResponse.results.length) {
                onHideComponent();
            }
            if (!isInitialSearch) {
                const searchCategory = selectedCategory.map((cat) => idToName(cat, this.props.categories));
                const citySearch = selectedPlace.map((place) => idToName(place, this.props.places));

                dataLayerPush({
                    'event': 'contentFilter',
                    'hits': formattedResponse ? formattedResponse.length : 0,
                    'newsType': 'news',
                    'newsFilterCategory': searchCategory,
                    'newsFilterCity': citySearch,
                    'newsFilterMonth': selectedMonth,
                    'newsFilterYear': selectedYear,
                });
            }
        });
    };

    render() {
        const {
            identifier,
            title,
            tag,
            showFilter,
            showDateFilter,
            pageListUrl,
            extendable,
            vertical,
            showRelated,
            hideCategoriesField,
            showFeaturedFirst,
        } = this.props;

        const {
            selectedPlace,
            selectedCategory,
            selectedMonth,
            selectedYear,
            entries,
            fetching,
            showShowAllButton,
            count,
        } = this.state;

        const showTitle = !!title || showFilter;

        let { places, categories, years, months } = this.props;
        categories = [{ id: '', name: 'Ämnen' }, ...categories];
        years = ['År', ...years];
        months = [{ id: '', name: 'Månad' }, ...months];

        const classes = classNames(
            s['CardNewsList'],
            {[s['CardNewsList--Vertical']]: vertical },
            {[s['CardNewsList--WithDateFilter']]: showDateFilter },
            {[s['CardNewsList--Related']]: showRelated },
        );

        return (
            <div className={classes}>
                {showTitle &&
                    <Title title={title} tag={tag}>
                        {showFilter && (
                            <div className={s['CardNewsList__FilterWrapper']}>
                                <div className={classNames(s['CardNewsList__SelectWrapper'], s['CardNewsList__SelectWrapper--Place'])}>
                                    <FilterSelect
                                        id={`${identifier}-card-news-list-place`}
                                        value={
                                            selectedPlace && selectedPlace.length
                                                ? selectedPlace[0]
                                                : ''
                                        }
                                        placeholder="Välj plats"
                                        onChange={this.handlePlaceOnChange}
                                        options={places.map((place) => ({
                                            value: place.id,
                                            label: place.name,
                                            isCounty: place.isCounty,
                                        }))}
                                        icon="Location"
                                        isLocation={true}
                                    />
                                </div>
                                {!hideCategoriesField && (
                                    <div className={s['CardNewsList__SelectWrapper']}>
                                        <FilterSelect
                                            id={`${identifier}-card-news-list-category`}
                                            value={
                                                selectedCategory &&
                                                selectedCategory.length
                                                    ? selectedCategory[0]
                                                    : ''
                                            }
                                            placeholder="Välj kategori"
                                            onChange={this.handleCategoryOnChange}
                                            options={categories.map((place) => ({
                                                value: place.id,
                                                label: place.name,
                                            }))}
                                            icon="Category"
                                        />
                                    </div>
                                )}

                                {!!showDateFilter && (
                                    <React.Fragment>
                                        <div className={classNames(s['CardNewsList__SelectWrapper'], s['CardNewsList__SelectWrapper--Month'])}>
                                            <select
                                                className={classNames(s['CardNewsList__Select'], s['CardNewsList__Select--Month'])}
                                                onChange={this.handleMonthChange}
                                                value={selectedMonth}
                                            >
                                                {months.map((month) => (
                                                    <option
                                                        key={month.id}
                                                        value={month.id}>
                                                        {month.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={classNames(s['CardNewsList__SelectWrapper'], s['CardNewsList__SelectWrapper--Year'])}>
                                            <select
                                                className={classNames(s['CardNewsList__Select'], s['CardNewsList__Select--Year'])}
                                                onChange={this.handleYearChange}
                                                value={selectedYear}
                                            >
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </Title>
                }

                {entries && (
                    <div className={s['CardNewsList__CardsWrapper']}>
                        {entries.map((card, index) => (
                            <div className={s['CardNewsList__Card']} key={index}>
                                <CardNews
                                    vertical={vertical}
                                    highlightFeatured={showFeaturedFirst}
                                    {...card}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {fetching && (
                    <div className={s['CardNewsList__Loader']}>
                        <AjaxLoader/>
                    </div>
                )}
                {!fetching && (!entries || !entries.length) && (
                    <div className={s['CardNewsList__NoHits']}>
                        Vi hittade inga nyheter som matchar din sökning
                    </div>
                )}

                {!fetching && !showShowAllButton && extendable && entries.length < count && (
                    <ButtonReadMore
                        text="Visa fler"
                        onClick={this.handleListExtension}
                    />
                )}

                {((!extendable && pageListUrl) || (showShowAllButton && pageListUrl)) && (
                    <a
                        href={pageListUrl}
                        className={s['CardNewsList__GoToPage']}>
                        Visa alla
                    </a>
                )}
            </div>
        );
    }
}

CardNewsList.defaultProps = {
    identifier: '',
    cardnews: [],
    showFilter: true,
    places: [],
    categories: [],
    showDateFilter: false,
    years: [],
    months: [],
    initialNumberOfCards: 6,
    extendable: true,
    vertical: false,
    excludeKeywords: [],
    onHideComponent: () => {
    },
    onShowComponent: () => {
    },
};

CardNewsList.propTypes = {
    initialNumberOfCards: PropTypes.number,
    extendable: PropTypes.bool,
    vertical: PropTypes.bool,
    excludeKeywords: PropTypes.array,
};

export default CardNewsList;
