/**
 * CardNews
 */

import React from 'react';
import classNames from 'classnames';
import Image from 'Components/Image';
import TagList from 'Components/TagList';
import Logo from 'Components/Logo';
import s from './CardNews.module.scss';

const CardNews = ({
    pageUrl = '',
    image = {},
    title = '',
    regions = [],
    text = '',
    formattedPublishedAt = '',
    genres = [],
    vertical = false,
    isFeatured = false,
    highlightFeatured = false,
}) => {
    const showFeaturedLabel = isFeatured && highlightFeatured;
    const classes = classNames(
        s['CardNews'],
        {[s['CardNews--Vertical']]: vertical },
        {[s['CardNews--NoImage']]: !image || !image.src },
        {[s['CardNews--Featured']]: showFeaturedLabel },
    );

    const regionlist = regions.map((region) => region.name).join(', ');

    const imageSizes = [
        '(min-width: 1200px) 365px',
        '(min-width: 960px) 33vw',
        '100vw',
    ];

    return (
        <a href={pageUrl} className={classes}>
            {image && image.src ? (
                <div className={s['CardNews__Image']}>
                    <Image {...image} sizes={imageSizes} />
                </div>
            ) : (
                <div className={classNames(s['CardNews__Image'], s['CardNews__Image--Placeholder'])}>
                    <Logo
                        className={[s['CardNews__Logo']]}
                        asBackground={true}
                    />
                </div>
            )}
            <article className={s['CardNews__Content']}>
                <h2 className={s['CardNews__Title']}>{title}</h2>
                {regionlist && (
                    <span className={s['CardNews__Region']}>{regionlist}</span>
                )}
                {text && <p className={s['CardNews__Text']}>{text}</p>}
                <span className={s['CardNews__Date']}>
                    {formattedPublishedAt}
                </span>
                <TagList tags={genres} />
            </article>
        </a>
    );
};

export default CardNews;
