/**
 * TagList
 */

import React from 'react';
import s from './TagList.module.scss';

const TagList = ({ tags }) => tags && tags.length ? (
    <ul className={s['TagList']}>
        {tags.map((tag, index) => <li key={index} className={s['TagList__Tag']}>{tag.name}</li>)}
    </ul>
) : null;

export default TagList;
